.root {
  height: 100%;
  background: url(../../image/taskReport/normal.png) no-repeat top / 100% auto #aafdf1;
  object {
    display: block;
    max-width: 1300px;
    max-height: 730px;
    margin: 0 auto;
  }
  .header {
    padding: 15px;
    .brand {
      width: 100%;
    }
    .title {
      font-size: 46px;
      color: #235D78
    }
    .btnBox {
      text-align: right;
      .btn {
        height: 55px;
        background-color: #FFFFFF;
        font-size: 20px;
        color: #4A4A4A;
        border-radius: 10px;
        margin: 0 10px;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px
        }
      }
    }
  }
}

@media (max-width:786px) {
  .root {
    .header {
      padding: 20px;
    }
  }
}