.root {
  min-height: 100vh;
  background: url(../../image/taskReport/normal.png) no-repeat top / 100% auto #aafdf1;
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 320px;
    padding: 24px;
    background: url(../../image/taskReport/header_bg.png) no-repeat top;
    background-size: cover;
    .closeBtn {
      position: absolute;
      top: 0;
      right: 0;
    }
    .headerTop {
      display: flex;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        span {
          font-size: 30px;
          color: #fff;
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }
    .noData {
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      color: #fff;
    }
    .card {
      position: relative;
      width: 183px;
      height: 162px;
      padding: 10px;
      border-radius: 15px;
      margin: 0 10px;
      display: flex;
      align-items: flex-end;
      
      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -66%);
      };
      > div {
        width: 100%;
        text-align: center;
        > div {
          padding: 3px 0;
        }
      }
    }
  }
  .paper {
    display: flex;
    // margin: 0 -10px;
    // justifyContent: space-between;
    align-items: center;
    min-height: 59px;
    margin-top: 12px;
    border-radius: 10px;
    padding: 10px 30px;
    color: #656565;
    span {
      display: inline-block;
      padding: 0 10px;
      word-wrap: break-word;
      word-break:break-all;
    }
    .rank {
      font-size: 23px;
      // minWidth: 50
    }
    .userName {
      font-size: 20px;
      font-weight: bold;
      flex-grow: 1;
    }
    .paperRight {
      font-size: 16px
    }
    .wpm {
      // marginRight: 40
    }
  }
  .runnerUp {
    background: transparent linear-gradient(180deg, #BCBCBC 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    color: #656565;
  }
  .champion {
    background: transparent linear-gradient(180deg, #FFEFCF 0%, #FFFDCB 100%) 0% 0% no-repeat padding-box;
    color: #B48D22;
  }
  .thirdPlace {
    background: transparent linear-gradient(180deg, #FF8B07 0%, #FFECE2 100%) 0% 0% no-repeat padding-box;
    color: #823A02
  }
}

@media (max-width:786px) {
  .root {
    .paper {
      padding: 10px;
    }
  }
}