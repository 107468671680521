.button_container{
  width: 180px;
  height: 80px;
  overflow: inherit;
  cursor: pointer;
  .button_triangle{
    top: 0;
    left: 0;
    display: inline-block;
    transform: rotateZ(-25deg);
    width: 40px;
    height: 88px;
    background-color: #fff;
    transform-origin: 1px -4px;
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.25);
  }
  .button_frame {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 25px;
    overflow: hidden;
  }
  .button_primary{
    vertical-align: top;
    width: 140px;
    height: 80px;
    background-color: #fff;
    color: rgb(27, 104, 88);
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    line-height: 80px;
    z-index: 10;
    box-shadow: 10px 0px 10px 6px rgba(0,0,0,0.25);
  }
}

.button_container_xs{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  color: rgb(27, 104, 88);
  cursor: pointer;
}

.drawer_triangle{
  position: absolute;
  top: 0;
  left: -330px;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  transform-origin: left top;
  z-index: -10;
}

.enter_move{
  animation: enterMove 0.5s ease forwards;
}
.exit_move{
  animation: exitMove 0.5s ease forwards;
}

@keyframes enterMove{
  from{
    transform: matrix(1, 0, 0, 2, 330, 0)
  }
  to{
    transform: matrix(0.948324, 0.317305, -0.634609, 1.89665, 330, 0);
  }
}

@keyframes exitMove{
  from{
    transform: matrix(0.948324, 0.317305, -0.634609, 1.89665, 330, 0);
  }
  to{
    transform: matrix(1, 0, 0, 2, 330, 0)
  }
}
